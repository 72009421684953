import {useBackgroundStyleConfigs} from 'hooks/useBackgroundStyleConfigs';
import Image from 'next/image';
import React, {FC} from 'react';

import {TypoComponentsContentSectionLarge} from 'lib/api/strapi';

import {Box, Grid, Typography} from 'components/basic-components';
import MarkdownContent from 'components/basic-components/MarkdownContent';
import {withErrorBoundary} from 'components/error/ErrorBoundary';

import styles from './ContentSectionLarge.module.scss';

const ContentSectionLarge: FC<TypoComponentsContentSectionLarge> = ({
  title,
  titleLevel,
  contentSection1,
  imageSection1,
  contentSection2,
  imageSection2,
}) => {
  const {textColor} = useBackgroundStyleConfigs();
  return (
    <Box className={styles.wrapper} sx={{color: textColor}}>
      <Grid container columnSpacing={{xxs: 0, md: '2%'}}>
        <Grid xxs={12} md={6}>
          <Typography
            component={titleLevel}
            className={styles.title}
            sx={{
              typography: {xxs: 'titleMobile', lg: 'h1'},
            }}
          >
            {title}
          </Typography>
          <MarkdownContent className={styles.markdown} content={contentSection1} />
        </Grid>
        <Grid xxs={12} md={6}>
          <Image
            width={imageSection1?.data?.attributes?.width}
            height={imageSection1?.data?.attributes?.height}
            className={styles.image}
            style={{
              boxShadow: `10px -10px 0 0 ${textColor}`,
            }}
            alt={imageSection1?.data?.attributes?.alternativeText}
            src={imageSection1?.data?.attributes?.url}
          />
        </Grid>
      </Grid>
      <Grid
        container
        sx={{flexDirection: {xxs: 'column-reverse', md: 'row'}}}
        columnSpacing={{xxs: 0, md: '2%'}}
      >
        <Grid xxs={12} md={6}>
          <Image
            width={imageSection2?.data?.attributes?.width}
            height={imageSection2?.data?.attributes?.height}
            className={styles.image}
            style={{
              boxShadow: `-10px -10px 0 0 ${textColor}`,
              marginLeft: '10px',
            }}
            alt={imageSection2?.data?.attributes?.alternativeText}
            src={imageSection2?.data?.attributes?.url}
          />
        </Grid>
        <Grid xxs={12} md={6} columnSpacing={{xxs: 0, md: '2%'}} sx={{marginTop: {lg: '35px'}}}>
          <MarkdownContent className={styles.markdown} content={contentSection2} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default withErrorBoundary(ContentSectionLarge, ' ContentSectionLarge');
